<template>
  <main style="max-width: 1200px; margin: 0 auto">
    <h1 class="text-center my-4">
      Propuesta de autoconsumo {{ propuesta.estudio.nombreProyecto }}
    </h1>
    <v-divider class="mb-4"></v-divider>
    <v-row>
      <v-col cols="12" md="6">
        <TarjetaDatos>
          <AuxInput title="Cups">{{
            propuesta.estudio.cups_principal.cups
          }}</AuxInput>
          <v-divider></v-divider>
          <AuxInput title="Dirección">{{
            propuesta.estudio.cups_principal.direccion
          }}</AuxInput>
          <v-divider></v-divider>
          <AuxInput title="Consumo"
            >{{ numberFormatter.format(propuesta.consumo_anual) }} kWh</AuxInput
          >
        </TarjetaDatos>
      </v-col>
      <v-col cols="12" md="6">
        <img :src="propuesta.plot" style="width: 100%; height: auto" alt="" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <TarjetaDatos>
          <AuxInput title="Número de paneles">{{ propuesta.paneles }}</AuxInput>
          <v-divider></v-divider>
          <AuxInput title="Potencia instalación"
            >{{
              numberFormatter.format(propuesta.potencia_instalacion)
            }}
            kWp</AuxInput
          >
          <v-divider></v-divider>
          <AuxInput title="Precio instalación">{{
            moneyFormatter.format(propuesta.precio_instalacion)
          }}</AuxInput>
          <v-divider></v-divider>
          <AuxInput title="Producción asegurada"
            >{{
              numberFormatter.format(
                propuesta.con_instalacion.produccion_asegurada_kWh
              )
            }}
            kWh</AuxInput
          >
        </TarjetaDatos>
      </v-col>
      <v-col cols="12" md="6">
        <TarjetaDatos>
          <AuxInput style="font-size: 1.2rem; font-weight: bold" title="Ahorro"
            >{{
              moneyFormatter.format(propuesta.con_instalacion.ahorro_anual)
            }}
            menos al año en tus facturas</AuxInput
          >
          <v-divider></v-divider>
          <AuxInput title="Años de retorno">{{
            numberFormatter.format(propuesta.años_de_retorno)
          }}</AuxInput>
        </TarjetaDatos>
      </v-col>
    </v-row>

    <div class="my-8 d-flex justify-center" @click.stop="descargarDocumento">
      <v-btn x-large color="primary">
        <v-icon left>mdi-download</v-icon>
        Descargar documento
      </v-btn>
    </div>
  </main>
</template>

<script>
import { downloadFile } from "@/utils/index.js";
export default {
  components: {
    AuxInput: () => import("@/components/AuxInput.vue"),
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
  },
  data() {
    return {
      numberFormatter: new Intl.NumberFormat("de-DE", {
        maximumFractionDigits: 2,
      }),
      moneyFormatter: new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }),
      propuesta: {
        ...this.$store.getters.getPropuesta,
        plot: "",
      },
    };
  },
  methods: {
    descargarDocumento() {
      // downloadFile(
      //   new File([this.propuesta.file], this.propuesta.filename, {
      //     mimeType: "application/pdf",
      //   }),
      //   true
      // );
      const linkSource = `data:application/pdf;base64,${this.propuesta.file}`;
      const downloadLink = document.createElement("a");
      const fileName = this.propuesta.filename;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
  },
};
</script>
